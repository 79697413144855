
<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-gray-50">
    <body class="h-full">
    ```
  -->
  <div class=" min-h-full flex flex-col justify-center py-12 sm:pt-20 sm:pb-28 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">Create an account</h2>
      <p class="mt-2 text-center text-sm text-gray-600">
        Already have one?
        {{ ' ' }}
        <router-link to="/login" class="font-medium text-lime-600 hover:text-lime-500">
          Sign in instead
        </router-link>
      </p>
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 sm:shadow- sm:rounded-lg sm:px-10">
        <form class="space-y-6" @submit.prevent="trySignUp" action="#" method="POST">
          <div>
            <label for="firstName" class="block text-sm font-medium text-gray-700"> First name </label>
            <div class="mt-1">
              <input v-model="firstName" id="firstName" name="first_name" type="text" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm" />
            </div>
          </div>

          <div>
            <label for="lastName" class="block text-sm font-medium text-gray-700"> Last name </label>
            <div class="mt-1">
              <input v-model="lastName" id="lastName" name="last_name" type="text" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm" />
            </div>
          </div>

          <div>
            <label for="email" class="block text-sm font-medium text-gray-700"> Email address </label>
            <div class="mt-1">
              <input v-model="email" id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm" />
            </div>
          </div>

          <div>
            <label for="password" class="block text-sm font-medium text-gray-700"> Password </label>
            <div class="mt-1">
              <input
                v-model="password"
                id="password"
                name="password"
                type="password"
                required
                placeholder="at least 8 characters"
                minlength="8"
                maxlength="60"
                class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-lime-500 focus:border-lime-500 sm:text-sm"
              />
            </div>
          </div>

          <div class="flex items-center justify-between">
            <p class="text-sm text-gray-600">
              By creating an account, you agree to our
              <router-link to="/terms" class="font-medium text-lime-600 hover:text-lime-500">
                terms of service
              </router-link>
            </p>
          </div>

          <div>
            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-lime-600 hover:bg-lime-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-500">
              <simple-spinner v-show="submitting" class="text-gray-200" />
              Continue
            </button>
          </div>
        </form>

      </div>
    </div>
  </div>
</template>


<script>
import { signInWithEmailAndPassword } from '@firebase/auth'
import SimpleSpinner from '../components/SimpleSpinner.vue'
import { auth } from '../firebase-auth'

const apiRoot = process.env.VUE_APP_API_ROOT;

export default {
  components: { SimpleSpinner },

  data() {
    return {
      submitting: false,

      firstName: '',
      lastName: '',
      email: '',
      password: '',
    }
  },

  methods: {
    trySignUp() {
      this.submitting = true;

      fetch(`${apiRoot}/auth/sign-up`, {
        method: 'post',
        body: JSON.stringify({
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          password: this.password,
        }),
        headers: {'Content-Type': 'application/json'}
      })
      .then(response => {
        if (response.ok) {
          return signInWithEmailAndPassword(auth, this.email, this.password)
        }
        // deal with error
      })
      .then(body => {
        return this.$router.push('onboarding');
      })
      .catch()
      .finally(() => this.submitting = false)
    }
  }
}
</script>
